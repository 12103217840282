<template>
    <div class="brand-insurance" :style="{'padding-bottom':isEdit?'96px':'16px'}">
        <div class="common-block item">
            <!-- 姓名 -->
            <div class="vehicle">{{ detail.carRegistration?.ownerName }}</div>
            <!-- 车系/车型/外饰/内饰 -->
            <div class="label">
                <div class="common-label-t">
                {{ detail.carOrderMaterial?.seriesName }} |
                {{ detail.carOrderMaterial?.modelName }} |
                {{ detail.carOrderMaterial?.colourName }} |
                {{ detail.carOrderMaterial?.interiorName }} 
                </div>
            </div>
            <div class="label">
                <div class="common-label-t">VIN : {{ detail.vin }}</div>
            </div>
        </div>
        <div class="common-block item">
          <FormVue class="brand-insurance-form" :isLabelTop="true" :isEdit="false" inputAlign="left" :list="buyCarInvoiceFileList" :formData="formData"/> 
        </div>
        <div class="common-block item">
            <FormVue ref="form" class="brand-insurance-form" :isLabelTop="true" :isEdit="isEdit && Boolean(einvoiceUrl)" inputAlign="left" :list="info" :formData="formData"/>
        </div>
        <div v-if="taskStatus==='4004003'" class="common-block item">
          <div class="common-title">{{ $t('保险信息') }}</div>
          <Insurance :policyInfo="policyInfo"/>
        </div>
        <div v-if="isEdit" class="common-footer">
            <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
            <van-button v-if="einvoiceUrl" class="btn submit" type="submit" :loading="submitLoading" @click="onSubmit">{{taskStatus==='4004004' ? $t('更新') : $t('提交')}}</van-button>
        </div>
    </div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import { thenResolve } from '@/utils/index'
import FormVue from '@/modules/components/form/index.vue'
import Insurance from './component/insurance.vue'
export default {
  components: { FormVue,Insurance },
  data(){
    return {
      detail:{}, 
      orderId: '',
      taskId: '',
      isEdit: true,
      buyCarInvoiceFileList: [], // 购车发票
      info:[],
      formData:{},
      taskDetail: {},
      submitLoading: false,
      taskStatus: '',
      einvoiceUrl: '',
      policyInfo: {}
    }
  },

  mounted(){
    const { orderId,einvoiceUrl,taskId } = this.$route.query
    this.orderId = orderId
    this.taskId = taskId
    this.einvoiceUrl = einvoiceUrl
    if (this.orderId) this.getDetail()
    if (this.taskId) this.deliveryTaskDetail()
    // 购车发票同步
    this.buyCarInvoiceFileList = [{
      label: this.$t('购车发票'),
      tips: einvoiceUrl ? this.$t('购车发票已同步') : this.$t('发票尚未同步'), 
      field:'einvoiceUrl',
      value:'',
      fileList: this.getUrlArr(einvoiceUrl),
      type:'upload',
      maxlength: 1,
      required: true,
    }]
  },

  methods:{
    // 获取订单详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      this.detail = res || {}      
    },
    getInfo() {
      const { taskDetail } = this
      const { vehicleQualifiedPhotoUrl = '' ,frameAndShopPhotoUrl = '',carPhotoUrl ='' } = taskDetail?.executionJson || {}
      this.formData = { vehicleQualifiedPhotoUrl,frameAndShopPhotoUrl ,carPhotoUrl }
      const info = [
        {
          label:this.$t('车辆合格证上传'),
          tips: this.einvoiceUrl? '' : this.$t('发票尚未同步，暂不支持上传'),
          field:'vehicleQualifiedPhotoUrl',
          value: '',
          fileList: this.getUrlArr(vehicleQualifiedPhotoUrl),
          type:'upload',
          maxlength: 1,
          required: false,
          maxSize: 20,
        },
        {
          label:this.$t('车辆与交付中心门头合影'),
          tips: this.einvoiceUrl? '' : this.$t('发票尚未同步，暂不支持上传'),
          field:'carPhotoUrl',
          value: '',
          fileList: this.getUrlArr(carPhotoUrl),
          type:'upload',
          maxlength: 1,
          required: false,
          maxSize: 20,
        },
        {
          label:this.$t('其他'), 
          tips: this.einvoiceUrl? this.$t('当日外卖消费小票与车架号合影、本地居住证明、本地社保卡等，以上材料任一即可。') : this.$t('发票尚未同步，暂不支持上传'),
          field:'frameAndShopPhotoUrl',
          value: '',
          fileList: this.getUrlArr(frameAndShopPhotoUrl),
          type:'upload',
          maxlength: 1,
          required: false,
          maxSize: 20,
        },
      ]        
      this.info = info
    },
    getUrlArr(str) {   
      const imgArr = []
      if (str){
        (str.split(',')).map((url=>{
          imgArr.push({
            status:'done',
            message:'',
            url,
            cosUrl: url
          })
        }))
      }
      return imgArr
    },
    // 任务详情
    deliveryTaskDetail() {
      deliveryServices.deliveryTaskDetail({
        taskId: this.taskId
      }).then(res=>{
        this.taskCode = res.taskCode
        this.taskStatus = res.taskStatus
        this.isEdit = this.taskStatus !== '4004003'
        this.taskDetail = res
        this.getInfo()
        // 获取保险信息
        if (this.taskStatus==='4004003'){
          this.getInsurance()
        }
      })
    },
    getInsurance(){
      deliveryServices
        .policyDetail({ orderId: this.orderId })
        .then((res) => {
          this.$set(this, 'policyInfo', res)
        })
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      if (this.submitLoading) return false
      this.submitLoading = true
      const { orderId,taskId,formData } = this
      if (!orderId && !taskId) return
      deliveryServices.deliveryExecution({
        executionJson:{
          taskCode: 'INSURANCE_MATERIAL_UPLOAD',
          ...formData,
        },
        id: taskId,
        taskStatus:'4004004',
      }).then(thenResolve).then(res=>{
        this.submitLoading = false
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      }).catch(() => {
        this.submitLoading = false
      })
    },
  }
}
</script>
<style lang="less" scoped>
.brand-insurance{
  padding: 16px;
}
.item{
  padding:0 12px 12px;
  color: @black;
  // margin-top: 10px;
  .common-t-l{
    &.required::after{
      content: '*';
      color: #EED484;
    }
  }
  &:not(:last-child){
    margin-bottom: 16px;
  }
  .vehicle{
    font-size: 16px;
    padding: 12px 0;
  }
  .label{
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .check-btn{
    color: #0040C5;
    cursor: pointer;
  }
}

.brand-insurance-form{
  /deep/.van-field__label{
    width: 100% !important;
  }
}
</style>